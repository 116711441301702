.wrapper {
  margin: var(--v-space-48) auto 0;
}

.wrapperPlain {
  margin: var(--v-space-64) auto 0;
}

.wrapper,
.wrapperPlain {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--v-from-md) {
    margin-top: calc(var(--v-space-8) * 7);
  }
}

.titleHeader {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  row-gap: var(--v-space-8);
  width: 100%;
  color: var(--v-color-foreground-secondary);
  transform: translateY(-100%);

  @media (--v-from-md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
